import "./App.css";
import ContactUs from "./component/contactUs/contactus";
import CopyWrite from "./component/copywrite/copywrite";
import DesignServices from "./component/designService/desgin_service";
import Header from "./component/header/header";
import HeaderPageImage from "./component/homePageImage/homePageImage";
import Infrastructure from "./component/infrastructure/infrastructure";
import JobDetails from "./component/jobPage/job_page";
import RPS from "./component/rps/rps";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
function App() {
  return (
    <div className="container-fluid g-0">
      <Header />
      {/* <HeaderPageImage /> */}
      {/*   <DesignServices /> */}
      {/*  <RPS></RPS> */}
      {/*  <Infrastructure /> */}
      {/*  <JobDetails /> */}
      {/*    <ContactUs /> */}

      <Routes>

        <Route path="/" element={<HeaderPageImage />} />
        <Route path="design" element={<DesignServices />} />
        <Route path="rps" element={<RPS />} />
        <Route path="infra" element={<Infrastructure />} />
        <Route path="jobs" element={<JobDetails />} />
        <Route path="contact" element={<ContactUs />} />
      </Routes>
      <CopyWrite />
    </div>
  );
}

export default App;
