import "./infrastructure.css";
import Jobs from "./jobs/jobs";

function Infrastructure() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 d-block position-relative">
          <img
            className="infraImg"
            src="./images/infra/infra_main.jpg"
            alt="Infrastructure"
          />
        </div>
        <div className="row g-0 d-flex justify-content-end position-absolute pt-3">
          <div className="col-5 pt-5">
            <div className="infraImgTxtPostion position-absolute">
              <div className="d-inline-flex flex-column ">
                <span className="font-face-BebasNeue infraImgTxt1 text-center">
                  OUR
                </span>
                <span className="font-face-BebasNeue infraImgTxt2 text-center">
                  INFRASTRUCTURE
                </span>
                <span className="font-face-OpenSans infraImgTxt3 p-4">
                  Our Permanent Placement service at Quantum Pulse Technologies is
                  designed to help businesses build a strong core team for
                  long-term success. We manage the entire recruitment process,
                  from sourcing and screening to interviewing and negotiating
                  employment offers Our Permanent
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Jobs />
    </>
  );
}

export default Infrastructure;
