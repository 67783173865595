import LetUsConnect from "./connect/connect";
import DesignServices from "./design_services/design_services";
import Empower from "./empower/empower";
import "./homePageImage.css";
import OurExpertise from "./our_expertise/our_expertise";
import QuntamPlus from "./quntam_plus/quntam_plus";

function HeaderPageImage() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12">
          <div className="imageBg text-center d-block">
            <img src="./images/home/homePage1.png" alt="heading"></img>
            <div className="imgHedBgDiv font-face-BebasNeue  d-flex align-items-center justify-content-center pt-2">
              <span className="imgHeadBgTxtSpan1">Unlock </span>
              <span className="imgHeadBgTxtSpan2">'TALENTS',</span>
              <span className="imgHeadBgTxtSpan3 ps-2">Accelerate </span>
              <span className="imgHeadBgTxtSpan4">'INNOVATION'</span>
            </div>
            <div className="imgHedBgDiv2 d-flex align-items-center">
              <span className="imgHedBgDiv2TxtSpan mx-auto font-face-OpenSans">
                At Quantum Pulse Technologies, we specialize in delivering
                world-class Recruitment Process Outsourcing (RPO) solutions and
                Design Services to empower businesses of all sizes. Whether
                you’re looking to streamline your hiring process or require
                cutting-edge design expertise, we combine industry-leading
                technology with deep sector knowledge to help you achieve your
                organizational goals.
              </span>
            </div>
          </div>
        </div>
      </div>
      <OurExpertise></OurExpertise>
      <QuntamPlus></QuntamPlus>
      <DesignServices></DesignServices>
      <Empower></Empower>
      <LetUsConnect></LetUsConnect>
    </>
  );
}

export default HeaderPageImage;
