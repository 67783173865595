import "./quntam_plus.css";

function QuntamPlus() {
  return (
    <>
      <div className="row quntamBg g-0">
        <div className="col-6  m-0 p-0 d-block position-relative">
          <img
            className="qunatmImg"
            alt="QuntamPlusImg"
            src="./images/home/quntam_plus.jpg"
          ></img>
          <div className="imgTxtMainDiv position-absolute">
            <div className="imgTxtBg"></div>
            <div className="d-flex font-face-BebasNeueRegular ">
              <div className="d-flex flex-column position-absolute text-end imgTxtPosition">
                <span className="imgTxt imgTxtTop pe-4 position-relative">
                  WHY
                </span>
                <span className="imgTxt imgTxtBottom pe-4 position-relative">
                  CHOOSE
                </span>
              </div>
              <span className="QueMarkTxt  font-face-BebasNeueRegular position-absolute imgQuesPosition">
                ?
              </span>
            </div>
          </div>
        </div>
        <div className="col-6  m-0 p-0  d-block position-relative">
          <div className="plusMainDiv position-relative">
            <div className="position-absolute plusHedMainDiv">
              <div className="d-flex flex-column font-face-BebasNeueRegular plusMoveLeft position-relative">
                <span className=" plusHedingLine1Txt">Quantum Pulse</span>
                <span className=" plusHedingLine2Txt position-relative">
                  Technologies
                </span>
              </div>
            </div>
            <div className="font-face-OpenSans plusDescTxt d-flex  homePagemargin position-relative flex-column position-relative plusDescTxtTop ">
              <span className="my-2 plusDescSpan d-flex">
                <div className="homePageListIcon"></div>
                Tailored Solutions: We customize our recruitment and design
                services to meet the specific needs of your business, ensuring
                alignment with your goals, culture, and vision.
              </span>
              <span className="my-2 plusDescSpan  d-flex">
              <div className="homePageListIcon"></div>
                Industry Expertise: With a team of seasoned professionals, we
                bring deep knowledge in talent management and technical design
                to offer holistic solutions.
              </span>
              <span className="my-2 plusDescSpan  d-flex">
              <div className="homePageListIcon"></div>
                Technology-Driven: Utilizing advanced tools and data-driven
                insights, we optimize every step of the recruitment and design
                process to deliver measurable results.
              </span>
              <span className="my-2 plusDescSpan  d-flex">
              <div className="homePageListIcon"></div>
                Global Reach, Local Expertise: We have an extensive network of
                talent and design experts, enabling us to provide both global
                perspective and local market expertise.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuntamPlus;
