import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
/* import $ from "jquery";
import Popper from "popper.js"; */
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./fonts/BebasNeue.otf";
import "./fonts/BebasNeue-Regular.ttf";
import "./fonts/Champagne & Limousines Bold Italic.ttf";
import "./fonts/Champagne & Limousines Bold.ttf";
import "./fonts/Champagne & Limousines Italic.ttf";
import "./fonts/Champagne & Limousines.ttf";
import "./fonts/GiorgioSans-Bold.ttf";
import "./fonts/Japers.otf";
import "./fonts/OpenSans-Bold.ttf";
import "./fonts/OpenSans-Light.ttf";
import "./fonts/OpenSans-Medium.ttf";
import "./fonts/OpenSans-Regular.ttf";
import "./fonts/Thasadith-Bold.ttf";
import "./fonts/Thasadith-BoldItalic.ttf";
import "./fonts/Thasadith-Italic.ttf";
import "./fonts/Thasadith-Regular.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
]);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>

    {/* <RouterProvider router={router} /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
