import "./connect.css";

function LetUsConnect() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center connectMainDiv pt-2 pb-3">
          <div className="d-flex flex-column conntMarg">
            <span className="connHeadTxt font-face-Thasadith">
              Lets connect
            </span>
            <span className="contTextColor font-face-OpenSans my-2">
              <img
                className="conntIcon"
                src="./images/home/i_mail.png"
                alt="Connect Mail Icon"
              ></img>
              Quantompulsecontactus@gmail.com
            </span>
            <span className="contTextColor font-face-OpenSans my-2">
              <img
                className="conntIcon"
                src="./images/home/i_phone.png"
                alt="Connect Mail Icon"
              ></img>
              044 - 4689794654, 2316879
            </span>
            <div className="d-flex pt-2">
              <img
                className="conntIcon"
                src="./images/home/i_loca.png"
                alt="Connect Mail Icon"
              ></img>
              <span className="contTextColor font-face-OpenSans">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore 
              </span>
            </div>
          </div>
          <div className="d-flex flex-column ms-4 pt-4 pb-4">
            <span className="contTextColor font-face-OpenSans">
              Quickly reach out us today to discover how we can help you build
              the workforce and technology solutions that drive your business
              forward.
            </span>
            <div className="connMsgDiv my-3"> </div>
            <div className="connBtnDiv my-0">
              <span>Send</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LetUsConnect;
